import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-header border-0 mt-4" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_5 = {
  key: 0,
  class: "card-toolbar"
}
const _hoisted_6 = { class: "nav" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = {
  class: "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 active",
  "data-bs-toggle": "tab",
  href: "#attachment_tab_images"
}
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = {
  class: "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1",
  "data-bs-toggle": "tab",
  href: "#attachment_tab_civils"
}
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = {
  class: "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4",
  "data-bs-toggle": "tab",
  href: "#attachment_tab_documents"
}
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = {
  class: "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4",
  "data-bs-toggle": "tab",
  href: "#attachment_tab_other"
}
const _hoisted_15 = { class: "card-body pt-5" }
const _hoisted_16 = { class: "tab-content" }
const _hoisted_17 = {
  class: "tab-pane fade active show",
  id: "attachment_tab_images"
}
const _hoisted_18 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_19 = { class: "d-flex flex-row-fluid flex-wrap align-items-center" }
const _hoisted_20 = { class: "flex-grow-1 me-2" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "text-muted fw-bold d-block pt-1" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "svg-icon-2 svg-icon-primary" }
const _hoisted_25 = {
  class: "tab-pane fade",
  id: "attachment_tab_civils"
}
const _hoisted_26 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_27 = { class: "d-flex flex-row-fluid flex-wrap align-items-center" }
const _hoisted_28 = { class: "flex-grow-1 me-2" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "text-muted fw-bold d-block pt-1" }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "svg-icon-2 svg-icon-primary" }
const _hoisted_33 = {
  class: "tab-pane fade",
  id: "attachment_tab_documents"
}
const _hoisted_34 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_35 = { class: "d-flex flex-row-fluid flex-wrap align-items-center" }
const _hoisted_36 = { class: "flex-grow-1 me-2" }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { class: "text-muted fw-bold d-block pt-1" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { class: "svg-icon-2 svg-icon-primary" }
const _hoisted_41 = {
  class: "tab-pane fade",
  id: "attachment_tab_other"
}
const _hoisted_42 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_43 = { class: "d-flex flex-row-fluid flex-wrap align-items-center" }
const _hoisted_44 = { class: "flex-grow-1 me-2" }
const _hoisted_45 = ["onClick"]
const _hoisted_46 = { class: "text-muted fw-bold d-block pt-1" }
const _hoisted_47 = ["onClick"]
const _hoisted_48 = { class: "svg-icon-2 svg-icon-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate('attachments')), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate('attachmentsCount', { count: _ctx.attachmentsCount })), 1)
      ]),
      (_ctx.attachmentsCount > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.translate('images')), 1)
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.translate('civilId')), 1)
              ]),
              _createElementVNode("li", _hoisted_11, [
                _createElementVNode("a", _hoisted_12, _toDisplayString(_ctx.translate('documents')), 1)
              ]),
              _createElementVNode("li", _hoisted_13, [
                _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.translate('other')), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachmentItems.propertyImage, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `propertyImage-${index}`,
              class: _normalizeClass([{
                'mb-7': _ctx.attachmentItems.propertyImage.length - 1 !== index,
              }, "d-flex align-items-sm-center"])
            }, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.getSymbolClasses(item)),
                  style: _normalizeStyle(`background-image: url('${_ctx.getAttachmentThumbnail(
                    item
                  )}')`)
                }, null, 6)
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("a", {
                    href: "#",
                    class: "text-gray-800 fw-bolder text-hover-primary fs-6 text-capitalize",
                    onClick: _withModifiers(($event: any) => (_ctx.downloadAttachment(item)), ["prevent"])
                  }, _toDisplayString(_ctx.getAttachmentName(item, index)), 9, _hoisted_21),
                  _createElementVNode("span", _hoisted_22, _toDisplayString(item.mime), 1)
                ]),
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-light btn-sm border-0 me-3",
                  onClick: _withModifiers(($event: any) => (_ctx.downloadAttachment(item)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_24, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr065.svg" })
                  ])
                ], 8, _hoisted_23)
              ])
            ], 2))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_25, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachmentItems.civilId, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `civilId-${index}`,
              class: _normalizeClass([{ 'mb-7': _ctx.attachmentItems.civilId.length - 1 !== index }, "d-flex align-items-sm-center"])
            }, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.getSymbolClasses(item)),
                  style: _normalizeStyle(`background-image: url('${_ctx.getAttachmentThumbnail(
                    item
                  )}')`)
                }, null, 6)
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("a", {
                    href: "#",
                    class: "text-gray-800 fw-bolder text-hover-primary fs-6 text-capitalize",
                    onClick: _withModifiers(($event: any) => (_ctx.downloadAttachment(item)), ["prevent"])
                  }, _toDisplayString(_ctx.getAttachmentName(item, index)), 9, _hoisted_29),
                  _createElementVNode("span", _hoisted_30, _toDisplayString(item.mime), 1)
                ]),
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-light btn-sm border-0 me-3",
                  onClick: _withModifiers(($event: any) => (_ctx.downloadAttachment(item)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_32, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr065.svg" })
                  ])
                ], 8, _hoisted_31)
              ])
            ], 2))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_33, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachmentItems.propertyDocument, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `propertyDocument-${index}`,
              class: _normalizeClass([{
                'mb-7': _ctx.attachmentItems.propertyDocument.length - 1 !== index,
              }, "d-flex align-items-sm-center"])
            }, [
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.getSymbolClasses(item)),
                  style: _normalizeStyle(`background-image: url('${_ctx.getAttachmentThumbnail(
                    item
                  )}')`)
                }, null, 6)
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("a", {
                    href: "#",
                    class: "text-gray-800 fw-bolder text-hover-primary fs-6 text-capitalize",
                    onClick: _withModifiers(($event: any) => (_ctx.downloadAttachment(item)), ["prevent"])
                  }, _toDisplayString(_ctx.getAttachmentName(item, index)), 9, _hoisted_37),
                  _createElementVNode("span", _hoisted_38, _toDisplayString(item.mime), 1)
                ]),
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-light btn-sm border-0 me-3",
                  onClick: _withModifiers(($event: any) => (_ctx.downloadAttachment(item)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_40, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr065.svg" })
                  ])
                ], 8, _hoisted_39)
              ])
            ], 2))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_41, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachmentItems.otherDocument, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `otherDocument-${index}`,
              class: _normalizeClass([{
                'mb-7': _ctx.attachmentItems.otherDocument.length - 1 !== index,
              }, "d-flex align-items-sm-center"])
            }, [
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.getSymbolClasses(item)),
                  style: _normalizeStyle(`background-image: url('${_ctx.getAttachmentThumbnail(
                    item
                  )}')`)
                }, null, 6)
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("a", {
                    href: "#",
                    class: "text-gray-800 fw-bolder text-hover-primary fs-6 text-capitalize",
                    onClick: _withModifiers(($event: any) => (_ctx.downloadAttachment(item)), ["prevent"])
                  }, _toDisplayString(_ctx.getAttachmentName(item, index)), 9, _hoisted_45),
                  _createElementVNode("span", _hoisted_46, _toDisplayString(item.mime), 1)
                ]),
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-light btn-sm border-0 me-3",
                  onClick: _withModifiers(($event: any) => (_ctx.downloadAttachment(item)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_48, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr065.svg" })
                  ])
                ], 8, _hoisted_47)
              ])
            ], 2))
          }), 128))
        ])
      ])
    ])
  ], 2))
}