
  import { defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';

  export default defineComponent({
    name: 'attachments-card',
    components: {},
    props: {
      widgetClasses: String,
      attachments: { type: Object, default: () => ({}) },
    },
    setup(props) {
      const { t, te } = useI18n();
      const attachmentItems = ref(props.attachments);

      const translate = (text, options) => {
        if (te(text)) {
          return t(text, options);
        } else {
          return text;
        }
      };

      const getAttachmentThumbnail = (item) => {
        if (item.mediaType !== 'image') {
          return '/media/svg/files/pdf.svg';
        } else {
          return item.name;
        }
      };

      const getAttachmentName = (item, index) => {
        return `${item.type.replace(/([A-Z])/g, ' $1')} - ${index + 1}`;
      };

      const downloadAttachment = (item) => {
        window.open(item.name, '_blank');
      };
      const attachmentsCount = Object.keys(attachmentItems.value).reduce(
        (previousValue, currentValue) => {
          return previousValue + attachmentItems.value[currentValue].length;
        },
        0
      );

      const getSymbolClasses = (item) => {
        let classes = 'symbol-label';
        if (item.mediaType !== 'image') {
          classes += ' contain-symbol';
        }
        return classes;
      };

      return {
        attachmentItems,
        getAttachmentThumbnail,
        downloadAttachment,
        translate,
        attachmentsCount,
        getAttachmentName,
        getSymbolClasses,
      };
    },
  });
