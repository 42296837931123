import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_8 = { class: "svg-icon svg-icon-2" }
const _hoisted_9 = { class: "card-body p-9" }
const _hoisted_10 = { class: "mb-10" }
const _hoisted_11 = { class: "mb-4" }
const _hoisted_12 = { class: "d-flex flex-wrap py-5" }
const _hoisted_13 = { class: "flex-equal me-5" }
const _hoisted_14 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_15 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_16 = { class: "text-gray-800 min-w-200px" }
const _hoisted_17 = { class: "text-gray-400" }
const _hoisted_18 = { class: "text-gray-800" }
const _hoisted_19 = ["href"]
const _hoisted_20 = { class: "text-gray-400" }
const _hoisted_21 = { class: "text-gray-800" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "text-gray-400" }
const _hoisted_24 = { class: "text-gray-800" }
const _hoisted_25 = { class: "text-gray-400" }
const _hoisted_26 = { class: "text-gray-800" }
const _hoisted_27 = { class: "badge badge-light-primary text-uppercase px-0" }
const _hoisted_28 = { class: "flex-equal" }
const _hoisted_29 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_30 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_31 = { class: "text-gray-800 min-w-200px" }
const _hoisted_32 = { class: "text-gray-400" }
const _hoisted_33 = { class: "text-gray-800" }
const _hoisted_34 = ["href"]
const _hoisted_35 = { class: "text-gray-400" }
const _hoisted_36 = { class: "text-gray-800" }
const _hoisted_37 = { class: "text-gray-400" }
const _hoisted_38 = { class: "text-gray-800" }
const _hoisted_39 = { class: "badge badge-light text-uppercase px-0" }
const _hoisted_40 = { key: 0 }
const _hoisted_41 = { class: "text-gray-400" }
const _hoisted_42 = { class: "text-gray-800" }
const _hoisted_43 = { class: "mb-0" }
const _hoisted_44 = { class: "mb-4" }
const _hoisted_45 = { class: "d-flex flex-wrap py-5" }
const _hoisted_46 = { class: "flex-equal me-5" }
const _hoisted_47 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_48 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_49 = { class: "text-gray-800 min-w-200px" }
const _hoisted_50 = { class: "text-gray-400" }
const _hoisted_51 = { class: "text-gray-800" }
const _hoisted_52 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_53 = { class: "text-gray-800 min-w-200px" }
const _hoisted_54 = { class: "text-gray-400" }
const _hoisted_55 = { class: "text-gray-800" }
const _hoisted_56 = { class: "flex-equal" }
const _hoisted_57 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_58 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_59 = { class: "text-gray-800 min-w-200px" }
const _hoisted_60 = { class: "text-gray-400" }
const _hoisted_61 = { class: "text-gray-800" }
const _hoisted_62 = { class: "text-gray-400" }
const _hoisted_63 = { class: "text-gray-800" }
const _hoisted_64 = { key: 0 }
const _hoisted_65 = { class: "text-gray-400" }
const _hoisted_66 = { class: "text-gray-800" }
const _hoisted_67 = { class: "row gy-10 gx-xl-10" }
const _hoisted_68 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_AttachmentCard = _resolveComponent("AttachmentCard")!

  return (_ctx.can('read', 'official-appraisal'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.translate('appraisalDetails')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  to: "/manage/appraisals/appraisals-listing",
                  class: "btn btn-light me-3"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('backToList')), 1)
                  ]),
                  _: 1
                }),
                (_ctx.can('update', 'official-appraisal'))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: `/manage/appraisals/appraisals-editing/${_ctx.appraisal.id}`,
                      class: "btn btn-primary"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_8, [
                          _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen019.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.translate('changeStatus')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h5", _hoisted_11, _toDisplayString(_ctx.translate('basicInfo')) + ":", 1),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("table", _hoisted_14, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.translate('applicantName')) + ": ", 1),
                      _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.appraisal.applicantName), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.translate('applicantEmail')) + ": ", 1),
                      _createElementVNode("td", _hoisted_18, [
                        _createElementVNode("a", {
                          href: `mailto:${_ctx.appraisal.applicantEmail}`,
                          class: "text-gray-800 text-hover-primary px-0"
                        }, _toDisplayString(_ctx.appraisal.applicantEmail), 9, _hoisted_19)
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.translate('applicantMobile')) + ": ", 1),
                      _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.appraisal.applicantMobile), 1)
                    ]),
                    (_ctx.appraisal.applicantType)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_22, [
                          _createElementVNode("td", _hoisted_23, _toDisplayString(_ctx.translate('applicantType')) + ": ", 1),
                          _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.appraisal.applicantType), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_25, _toDisplayString(_ctx.translate('status')) + ":", 1),
                      _createElementVNode("td", _hoisted_26, [
                        _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.appraisal.statusTitle), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("table", _hoisted_29, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.translate('appraiserName')) + ": ", 1),
                      _createElementVNode("td", _hoisted_31, _toDisplayString(_ctx.appraisal.appraiserName), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_32, _toDisplayString(_ctx.translate('appraiserEmail')) + ": ", 1),
                      _createElementVNode("td", _hoisted_33, [
                        _createElementVNode("a", {
                          href: `mailto:${_ctx.appraisal.appraiserEmail}`,
                          class: "text-gray-800 text-hover-primary px-0"
                        }, _toDisplayString(_ctx.appraisal.appraiserEmail), 9, _hoisted_34)
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_35, _toDisplayString(_ctx.translate('appraiserMobile')) + ": ", 1),
                      _createElementVNode("td", _hoisted_36, _toDisplayString(_ctx.appraisal.appraiserPhone), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_37, _toDisplayString(_ctx.translate('bankAppraisal')) + ": ", 1),
                      _createElementVNode("td", _hoisted_38, [
                        _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.translate(_ctx.appraisal.isBank ? 'yes' : 'no')), 1)
                      ])
                    ]),
                    (_ctx.appraisal.bankName)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_40, [
                          _createElementVNode("td", _hoisted_41, _toDisplayString(_ctx.translate('bankName')) + ":", 1),
                          _createElementVNode("td", _hoisted_42, _toDisplayString(_ctx.appraisal.bankName), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("h5", _hoisted_44, _toDisplayString(_ctx.translate('propertyInfo')) + ":", 1),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  _createElementVNode("table", _hoisted_47, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_48, _toDisplayString(_ctx.translate('appraisalPropertyStatus')) + ": ", 1),
                      _createElementVNode("td", _hoisted_49, _toDisplayString(_ctx.appraisal.appraisalPropertyStatus), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_50, _toDisplayString(_ctx.translate('appraisalPropertyTypes')) + ": ", 1),
                      _createElementVNode("td", _hoisted_51, _toDisplayString(_ctx.appraisal.appraisalPropertyTypes), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_52, _toDisplayString(_ctx.translate('areaName')) + ": ", 1),
                      _createElementVNode("td", _hoisted_53, _toDisplayString(_ctx.appraisal.areaName), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_54, _toDisplayString(_ctx.translate('areaSize')) + ":", 1),
                      _createElementVNode("td", _hoisted_55, _toDisplayString(_ctx.appraisal.size), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_56, [
                  _createElementVNode("table", _hoisted_57, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_58, _toDisplayString(_ctx.translate('price')) + ": ", 1),
                      _createElementVNode("td", _hoisted_59, _toDisplayString(_ctx.appraisal.price), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_60, _toDisplayString(_ctx.translate('remarks')) + ":", 1),
                      _createElementVNode("td", _hoisted_61, _toDisplayString(_ctx.appraisal.remarks), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_62, _toDisplayString(_ctx.translate('additionalDetails')) + ": ", 1),
                      _createElementVNode("td", _hoisted_63, _toDisplayString(_ctx.appraisal.additionalDetails), 1)
                    ]),
                    (_ctx.appraisal.address)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_64, [
                          _createElementVNode("td", _hoisted_65, _toDisplayString(_ctx.translate('address')) + ":", 1),
                          _createElementVNode("td", _hoisted_66, _toDisplayString(_ctx.appraisal.address), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_67, [
          _createElementVNode("div", _hoisted_68, [
            _createVNode(_component_AttachmentCard, {
              "widget-classes": "card-xxl-stretch mb-5 mb-xl-10",
              attachments: _ctx.appraisal.appraisalsAttachments
            }, null, 8, ["attachments"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}